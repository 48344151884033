/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect, useState } from "react";

// -----------------LIBRARIES IMPORTS------------------- //
// @ts-ignore
import {
    Button,
    Typography,
    ModalConfirmation as Modal,
    Knob,
    ProgressBar,
    ModalConfirmation,
    Input,
    SideModal,
    Select,
} from "design_system/src";
import { Grid } from "@mui/material";
import moment from "moment";
import "moment/locale/es";

// -----------------DATA IMPORTS------------------- //
import { priceFormatFinancial, priceMXNTotal } from "helpers/prices/prices";
import { TDataozoner } from "models/ozoner.interface";
import {
    FetchCreditByOzonerId,
    FetchCreditTotals,
    UpdateCreditDeposit,
    completeCredit,
    editCredit,
    returnCredit,
    settleDebt,
    syncPC,
} from "helpers/fetchs/credits";
import {
    CreditStatus,
    CreditStatusFlags,
    CreditStatusFlagsValues,
    CreditStatusValues,
    TCredit,
    TCreditTotals,
} from "models/credit.interface";
import {
    CreditPaymentStatusValues,
    TCreditPayment,
} from "models/credit-payment.interface";
import { getOzonioById } from "helpers/fetchs/ozonios";

// -----------------STATIC IMPORTS------------------- //
import "../../ozoner.scss";
import { ReactComponent as Whatsapp } from "design_system/src/static/icons/whatsapp.svg";
import { ReactComponent as Edit } from "design_system/src/static/icons/edit-text.svg";
import { ReactComponent as Refresh } from "design_system/src/static/icons/refresh.svg";
import { ReactComponent as PDF } from "design_system/src/static/icons/pdf.svg";
import { ReactComponent as LinkSvg } from "design_system/src/static/icons/link.svg";
import { ReactComponent as Download } from "design_system/src/static/icons/download-bracket.svg";
import { ReactComponent as User } from "design_system/src/static/icons/verified-user.svg";
import { ReactComponent as Credit } from "design_system/src/static/icons/credit-card-2.svg";
import { ReactComponent as Money } from "design_system/src/static/icons/money.svg";
import { ReactComponent as Calendar } from "design_system/src/static/icons/date.svg";

import { ReactComponent as Check } from "design_system/src/static/icons/checkmark.svg";
import { ReactComponent as Eye } from "design_system/src/static/icons/eye.svg";
import { ReactComponent as Reload } from "design_system/src/static/icons/reload.svg";
import { ReactComponent as Close } from "design_system/src/static/icons/close-lg.svg";
import { ReactComponent as Up } from "design_system/src/static/icons/success-plan.svg";
import { ReactComponent as Wallet } from "design_system/src/static/icons/wallet.svg";
import { ReactComponent as Tag } from "design_system/src/static/icons/price-tag-rotate.svg";
import { ReactComponent as Discount } from "design_system/src/static/icons/discount.svg";

import { setCreditsFilter } from "helpers/localStorage/filterStorage";
import { LightTooltip } from "components/hocs/tooltip/Tooltip";
import CreditPaymentsTable from "components/hocs/creditPaymentsTable/CreditPaymentsTable";
import { statusVehicles } from "models/vehicle.interface";
import Rejected from "views/ozoner/details/modals/Rejected";
import Dropzone from "react-dropzone";
import Swal from "sweetalert2";
import { addFileForm, deleteFileForm } from "helpers/fetchs/solicitudes";
import { toasterNotification } from "helpers/globalNotifications/globalNotifications";
import { toasterOptions } from "enums/toaster.enum";
import { commonErrorHandler } from "helpers/errors/errors";
import { ozonerContext } from "../OzonerContext";

// -----------------COMPONENTS IMPORTS------------------- //

const classLevel: any = {
    AAA: { value: 100, color: "green_300" },
    AA: { value: 80, color: "green_300" },
    A: { value: 60, color: "green_300" },
    B: { value: 40, color: "yellow_100" },
    C: { value: 20, color: "red_300" },
};

const formatName = (name: string) => name.replaceAll(" ", "_");

interface DataProps {
    setOpenStatusHistoric: Function;
}
function CreditOzoner({ setOpenStatusHistoric }: DataProps) {
    // -----------------DATA STATES------------------- //
    const [endedDate, setEndedDate] = useState<any>("");
    const [loading, setLoading] = useState<boolean>(false);

    // -----------------OPEN STATES------------------- //
    const [openEnded, setOpenEnded] = useState(false);
    const [openTags, setOpenTags] = useState(false);
    const [editTags, setEditTags] = useState<string[]>([]);

    const [isConfirmationLoading, setIsConfirmationLoading] = useState(false);

    const [statusChange, setSubstatusChange] = useState<any>("");
    const [openStatusChange, setOpenStatusChange] = useState<boolean>(false);
    const [changeStatusLoading, setChangeStatusLoading] = useState(false);

    const [openSettle, setOpenSettle] = useState(false);
    const [writeOffAmount, setWriteOffAmount] = useState(0);
    const [writeOffDiscount, setWriteOffDiscount] = useState(0);
    const [loadingSettle, setLoadingSettle] = useState(false);

    const {
        ozoner,
        vehicle,
        financialForm,
        fetchData,
        handleDetailsVeh,
        setResult,
        ozoniosList,
    } = useContext(ozonerContext);

    const closeSettle = () => {
        setOpenSettle(false);
        setWriteOffDiscount(0);
    };

    const updateStatus = (status: string) => {
        setChangeStatusLoading(true);
        editCredit(financialForm.credit._id ?? "", { status })
            .then(() => {
                setChangeStatusLoading(false);
                setOpenStatusChange(false);
                setSubstatusChange("");
                setResult();
            })
            .catch((err) => {
                setChangeStatusLoading(false);
                toasterNotification({
                    // eslint-disable-next-line no-nested-ternary
                    msg: err
                        ? Array.isArray(err)
                            ? err[0]
                            : err
                        : "Ocurrio un error",
                    toasterType: toasterOptions.error,
                    style: "light",
                });
            });
    };

    useEffect(() => {
        setWriteOffAmount(financialForm?.creditTotals?.totalDebt);
    }, [financialForm]);

    const changeEndedDate = () => {
        if (financialForm?.credit) {
            editCredit(financialForm?.credit._id, { endedAt: endedDate }).then(
                () => {
                    fetchData();
                }
            );
        }
    };
    const updateTags = () => {
        if (financialForm?.credit) {
            setIsConfirmationLoading(true);
            const up: any = {};
            Object.keys(CreditStatusFlagsValues).forEach((key) => {
                up[key] = editTags.includes(key);
            });
            editCredit(financialForm?.credit._id, up)
                .then(() => {
                    setIsConfirmationLoading(false);
                    fetchData();
                    setOpenTags(false);
                    setEditTags([]);
                    toasterNotification({
                        toasterType: toasterOptions.success,
                        msg: "Etiquetas actualizadas",
                        style: "light",
                    });
                })
                .catch((e) => {
                    setLoading(false);
                    toasterNotification({
                        msg: e ?? "Ocurrio un error",
                        toasterType: toasterOptions.error,
                        style: "light",
                    });
                });
        }
    };

    const settleCredit = () => {
        const data = {
            writeOffAmount,
            writeOffDiscount: writeOffDiscount / 100,
        };
        if (financialForm?.credit?._id) {
            setLoadingSettle(true);
            settleDebt(financialForm.credit._id, data)
                .then(() => {
                    setLoadingSettle(false);
                    setResult();
                })
                .catch((e) => {
                    setLoadingSettle(false);
                    commonErrorHandler(e);
                });
        }
    };

    return (
        <>
            {ozoner && financialForm?.credit && financialForm?.creditTotals && (
                <Grid
                    container
                    direction="row"
                    spacing={3}
                    className="h_100_per p_t_md"
                >
                    <Grid item xs={3}>
                        <div className="bg_neutral_0 border_neutral_400 border_solid border_1 br_sm">
                            <div
                                id="base-credit"
                                className="p_md border_b_solid border_1 border_neutral_400 "
                            >
                                <div className="m_b_md display_flex flex_gap_md flex_align_center">
                                    <Button
                                        variant="icon"
                                        scale="small"
                                        icon={<Tag />}
                                        subvariant="edit"
                                        onClick={() => {
                                            setOpenTags(true);
                                            setEditTags(
                                                Object.keys(
                                                    CreditStatusFlagsValues
                                                ).filter(
                                                    (key) =>
                                                        (
                                                            financialForm?.credit as any
                                                        )[key]
                                                )
                                            );
                                        }}
                                    />

                                    <div className="display_flex flex_align_center flex_wrap flex_gap_xs">
                                        {Object.keys(CreditStatusFlagsValues)
                                            .filter(
                                                (key) =>
                                                    (
                                                        financialForm?.credit as any
                                                    )[key]
                                            )
                                            .map((key) => (
                                                <LightTooltip
                                                    title={
                                                        CreditStatusFlagsValues[
                                                            key as CreditStatusFlags
                                                        ]?.tooltip ?? key
                                                    }
                                                >
                                                    <div>
                                                        <Typography
                                                            scale="xxsmall"
                                                            weight="600"
                                                            className="br_xxs p_x_xs p_y_xxxs text_center text_red_300 pos_relative bg_red_200_nocontrast text_no_break"
                                                        >
                                                            {CreditStatusFlagsValues[
                                                                key as CreditStatusFlags
                                                            ]?.title ?? key}
                                                        </Typography>
                                                    </div>
                                                </LightTooltip>
                                            ))}
                                        {Object.keys(
                                            CreditStatusFlagsValues
                                        ).filter(
                                            (key) =>
                                                (financialForm?.credit as any)[
                                                    key
                                                ]
                                        ).length === 0 ? (
                                            <Typography
                                                scale="medium"
                                                weight="400"
                                                className=""
                                            >
                                                Crédito sin etiquetas
                                            </Typography>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="display_flex flex_gap_md flex_align_center m_b_md">
                                    <Knob
                                        value={
                                            classLevel[
                                                financialForm?.credit
                                                    ?.creditClassification
                                            ]?.value ?? 0
                                        }
                                        variant="gauge"
                                        symbol="%"
                                        color={
                                            classLevel[
                                                financialForm?.credit
                                                    ?.creditClassification
                                            ]?.color ?? "red_300"
                                        }
                                        className="dim_xxxl border_transparent"
                                    />
                                    <div>
                                        <Typography
                                            scale="medium"
                                            weight="600"
                                            className=""
                                        >
                                            Crédito{" "}
                                            {financialForm?.credit?.code}{" "}
                                        </Typography>
                                        <Typography
                                            scale="xsmall"
                                            weight="400"
                                            className=""
                                            textColor={
                                                classLevel[
                                                    financialForm?.credit
                                                        ?.creditClassification
                                                ]?.color ?? "neutral_600"
                                            }
                                        >
                                            Clasificación del Ozoner:{" "}
                                            {financialForm?.credit
                                                ?.creditClassification ?? "N/D"}
                                        </Typography>
                                    </div>
                                </div>
                                <Typography
                                    scale="medium"
                                    weight="400"
                                    className="m_b_xs"
                                    textColor="neutral_800"
                                >
                                    Fecha de activación
                                </Typography>
                                <Typography
                                    scale="medium"
                                    weight="600"
                                    className="m_b_md"
                                    textColor="neutral_800"
                                >
                                    {financialForm.credit.activatedAt
                                        ? moment(
                                              financialForm.credit.activatedAt
                                          ).utcOffset(-360).format("DD/MM/yyyy")
                                        : "--"}
                                </Typography>
                                <Typography
                                    scale="medium"
                                    weight="400"
                                    className="m_b_xs"
                                    textColor="neutral_800"
                                >
                                    ID Comprador
                                </Typography>
                                <Typography
                                    scale="medium"
                                    weight="600"
                                    className="m_b_md"
                                    textColor="neutral_800"
                                >
                                    {financialForm?.credit?.codeOzoner || ""}
                                </Typography>
                                <Typography
                                    scale="medium"
                                    weight="400"
                                    className="m_b_xs"
                                    textColor="neutral_800"
                                >
                                   Responsable de Ventas
                                </Typography>
                                <Typography
                                    scale="medium"
                                    weight="600"
                                    className=""
                                    textColor="neutral_800"
                                >
                                    {ozoniosList.find((form:any)=>form.value===financialForm?.form?.sellResponsible || "")?.label??"Sin asignar"}
                                </Typography>
                            </div>
                            {financialForm.credit.endedAt ? (
                                <div
                                    id="finalizacion-ozoner"
                                    className="p_md border_b_solid border_1 border_neutral_400 pos_relative"
                                >
                                    <Typography
                                        scale="medium"
                                        weight="400"
                                        className="m_b_xs"
                                        textColor="neutral_800"
                                    >
                                        Fecha de finalizacion del contrato
                                    </Typography>
                                    <Typography
                                        scale="medium"
                                        weight="600"
                                        className="m_b_md"
                                        textColor="neutral_800"
                                    >
                                        {moment(financialForm.credit.endedAt)
                                            .utcOffset(-360)
                                            .format("DD/MM/yyyy")}
                                    </Typography>
                                    <Button
                                        onClick={() => setOpenEnded(true)}
                                        variant="icon"
                                        icon={<Edit />}
                                        subvariant="edit"
                                        scale="small"
                                        className="edit-button-deposit"
                                    />
                                </div>
                            ) : null}
                            <div
                                id="estado-ozoner"
                                className="p_md border_b_solid border_1 border_neutral_400"
                            >
                                <div className="display_flex flex_justify_between flex_align_center m_b_xs">
                                    <Typography
                                        scale="medium"
                                        weight="400"
                                        textColor="neutral_800"
                                        className="text_no_break"
                                    >
                                        Estado Crédito:
                                    </Typography>
                                    <LightTooltip
                                        title={
                                            CreditStatusValues[
                                                financialForm.credit
                                                    ?.status as keyof typeof CreditStatus
                                            ]?.tooltip ??
                                            financialForm.credit?.status
                                        }
                                    >
                                        <div>
                                            <Typography
                                                scale="medium"
                                                weight="600"
                                                className={`br_xs p_x_md p_y_xxxs text_center text_neutral_0 pos_relative
                                        bg_${
                                            (CreditStatusValues as any)[
                                                financialForm.credit.status
                                            ]?.color
                                        }_nocontrast`}
                                            >
                                                {
                                                    (CreditStatusValues as any)[
                                                        financialForm.credit
                                                            .status
                                                    ]?.title
                                                }
                                            </Typography>
                                        </div>
                                    </LightTooltip>
                                </div>
                                {!(CreditStatusValues as any)[
                                    financialForm.credit.status
                                ]?.fixed ? (
                                    <Select
                                        className=""
                                        onChange={(e: any) => {
                                            setSubstatusChange(e.target.value);
                                            setOpenStatusChange(true);
                                        }}
                                        value={financialForm.credit.status}
                                        placeholder="Selecciona el subestado deseado"
                                        options={Object.keys(CreditStatusValues)
                                            .filter((status) => {
                                                const creditStatus = (
                                                    CreditStatusValues as any
                                                )[financialForm.credit.status];
                                                const currentStatus = (
                                                    CreditStatusValues as any
                                                )[status];
                                                if (currentStatus.trigerred)
                                                    return false;
                                                if (
                                                    status ===
                                                    CreditStatus.writeOff
                                                )
                                                    return true;
                                                if (
                                                    currentStatus.writeOff !==
                                                    creditStatus.writeOff && status !== CreditStatus.devolution
                                                )
                                                    return false;
                                                return true;
                                            })
                                            .map((status) => ({
                                                value: status,
                                                label: (
                                                    CreditStatusValues as any
                                                )[status].title,
                                            }))}
                                    />
                                ) : null}
                                <Button
                                    variant="outline"
                                    scale="small"
                                    className="w_100_per m_t_md"
                                    onClick={() => setOpenStatusHistoric(true)}
                                >
                                    Historial de estados
                                </Button>
                            </div>
                            <div
                                id="validate-soli-ozoner"
                                className="border_b_solid border_1 border_neutral_400 display_flex flex_col"
                            >
                                <div className="display_flex">
                                    <div className="flex_grow_1 flex_basis_0 border_solid border_1 border_neutral_400 p_y_xxs">
                                        <Typography
                                            scale="xxsmall"
                                            weight="400"
                                            textColor="neutral_600"
                                            className="text_no_break text_center m_b_xxs"
                                        >
                                            Deposito
                                        </Typography>
                                        <Typography
                                            scale="small"
                                            weight="600"
                                            textColor="green_300"
                                            className="text_no_break text_center"
                                        >
                                            {priceFormatFinancial(
                                                financialForm?.credit
                                                    ?.deposit ?? 0,
                                                0
                                            )}
                                        </Typography>
                                    </div>
                                    <div className="flex_grow_1 flex_basis_0 border_solid border_1 border_neutral_400 p_y_xxs">
                                        <Typography
                                            scale="xxsmall"
                                            weight="400"
                                            textColor="neutral_600"
                                            className="text_no_break text_center m_b_xxs"
                                        >
                                            Pago por adelantado
                                        </Typography>
                                        <Typography
                                            scale="small"
                                            weight="600"
                                            textColor="secondary_500"
                                            className="text_no_break text_center"
                                        >
                                            {priceFormatFinancial(
                                                financialForm?.form
                                                    ?.advancedMoney ?? 0,
                                                0
                                            )}
                                        </Typography>
                                    </div>
                                </div>
                                <div className="display_flex">
                                    <div className="flex_grow_1 flex_basis_0 border_solid border_1 border_neutral_400 p_y_xxs">
                                        <Typography
                                            scale="xxsmall"
                                            weight="400"
                                            textColor="neutral_600"
                                            className="text_no_break text_center m_b_xxs"
                                        >
                                            Tiempo
                                        </Typography>
                                        <Typography
                                            scale="small"
                                            weight="600"
                                            textColor="neutral_1000"
                                            className="text_no_break text_center"
                                        >
                                            {financialForm?.credit?.duration}{" "}
                                            Semanas
                                        </Typography>
                                    </div>
                                    <div className="flex_grow_1 flex_basis_0 border_solid border_1 border_neutral_400 p_y_xxs">
                                        <Typography
                                            scale="xxsmall"
                                            weight="400"
                                            textColor="neutral_600"
                                            className="text_no_break text_center m_b_xxs"
                                        >
                                            Cuota
                                        </Typography>
                                        <Typography
                                            scale="small"
                                            weight="600"
                                            textColor="primary_300"
                                            className="text_no_break text_center"
                                        >
                                            {priceFormatFinancial(
                                                financialForm?.credit?.total ??
                                                    0,
                                                0
                                            )}
                                        </Typography>
                                    </div>
                                </div>
                                <div className="display_flex">
                                    <div className="flex_grow_1 flex_basis_0 border_solid border_1 border_neutral_400 p_y_xxs">
                                        <Typography
                                            scale="xxsmall"
                                            weight="400"
                                            textColor="neutral_600"
                                            className="text_no_break text_center m_b_xxs"
                                        >
                                            Total credito sin deposito
                                        </Typography>
                                        <Typography
                                            scale="small"
                                            weight="600"
                                            textColor="green_300"
                                            className="text_no_break text_center"
                                        >
                                            {priceFormatFinancial(
                                                (financialForm?.credit?.total ??
                                                    0) *
                                                    (financialForm?.credit
                                                        ?.duration ?? 0),
                                                0
                                            )}
                                        </Typography>
                                    </div>
                                </div>
                                <div className="display_flex ">
                                    <div className="flex_grow_1 flex_basis_0 border_solid border_1 border_neutral_400 p_y_xxs">
                                        <Typography
                                            scale="xxsmall"
                                            weight="400"
                                            textColor="neutral_600"
                                            className="text_no_break text_center m_b_xxs"
                                        >
                                            Alianza
                                        </Typography>
                                        <Typography
                                            scale="small"
                                            weight="600"
                                            textColor="neutral_900"
                                            className="text_no_break text_center"
                                        >
                                            {financialForm?.form?.partnership ??
                                                "--"}
                                        </Typography>
                                    </div>
                                    <div className="flex_grow_1 flex_basis_0 border_solid border_1 border_neutral_400 p_y_xxs">
                                        <Typography
                                            scale="xxsmall"
                                            weight="400"
                                            textColor="neutral_600"
                                            className="text_no_break text_center m_b_xxs"
                                        >
                                            Segmentacion Crédito
                                        </Typography>
                                        <Typography
                                            scale="small"
                                            weight="600"
                                            textColor="neutral_900"
                                            className="text_no_break text_center"
                                        >
                                            {
                                                financialForm?.form
                                                    ?.creditSegmentation
                                            }
                                        </Typography>
                                    </div>
                                </div>
                                <div className="display_flex flex_gap_md flex_align_center border_solid border_1 border_neutral_400 p_xs">
                                    <div className="br_xs overflow_hidden foto_moto">
                                        <img
                                            src={
                                                vehicle.images[0]
                                                    ? vehicle.images[0].url
                                                    : `${process.env.REACT_APP_STORAGE_BASE_URL}public/uploads/generic-moto.png`
                                            }
                                            alt="moto detail"
                                        />
                                    </div>
                                    <div className="flex_grow_1 flex_center_col">
                                        <Typography
                                            scale="small"
                                            weight="600"
                                            className="inventory_link cursor_pointer"
                                            onClick={(e) =>
                                                handleDetailsVeh(
                                                    e,
                                                    vehicle.internalId
                                                )
                                            }
                                        >
                                            {vehicle.internalId}
                                        </Typography>

                                        <Typography
                                            scale="xxsmall"
                                            weight="400"
                                            className="m_b_xxs"
                                        >
                                            {vehicle.brand && vehicle.model
                                                ? `${vehicle.brand.name} ${
                                                      vehicle.model.name
                                                  } ${vehicle.suffix ?? ""}`
                                                : "no definido"}
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Grid>

                    <Grid item xs={9}>
                        <div className="display_flex flex_gap_md flex_align_stretch m_b_md">
                            <div className="br_xxs border_neutral_400 border_solid border_1 flex_basis_0 flex_grow_1 bg_neutral_0 p_y_md p_x_lg display_flex flex_gap_xl flex_align_center">
                                <Knob
                                    value={
                                        financialForm?.credit?.payments.filter(
                                            (ele: any) =>
                                                ele.status === "paid" &&
                                                ele.uuidContract !== "porcobrar"
                                        ).length
                                    }
                                    color="green_300"
                                    total={financialForm?.credit?.duration}
                                    variant="classic"
                                    className="dim_xxxl border_transparent flex_shrink_0"
                                />
                                <div>
                                    <Typography
                                        scale="medium"
                                        weight="600"
                                        className=""
                                    >
                                        Cuotas pagadas
                                    </Typography>
                                    <Typography
                                        scale="xsmall"
                                        weight="400"
                                        className=""
                                    >
                                        {financialForm.credit.duration -
                                            financialForm.credit.payments.filter(
                                                (ele: any) =>
                                                    ele.status === "paid" &&
                                                    ele.uuidContract !==
                                                        "porcobrar"
                                            ).length}{" "}
                                        cuotas por pagar
                                    </Typography>
                                </div>
                            </div>
                            <div className="br_xxs border_neutral_400 border_solid border_1 flex_basis_0 flex_grow_1 bg_neutral_0 p_y_md p_x_lg display_flex flex_gap_xl flex_align_center">
                                <Knob
                                    value={
                                        financialForm?.credit?.payments.filter(
                                            (ele: any) =>
                                                ele.status === "expired"
                                        ).length
                                    }
                                    color="red_300"
                                    total={
                                        financialForm.credit.duration -
                                        financialForm.credit.payments.filter(
                                            (ele: any) => ele.status === "paid"
                                        ).length
                                    }
                                    variant="classic"
                                    className="dim_xxxl border_transparent flex_shrink_0"
                                />
                                <div>
                                    <Typography
                                        scale="medium"
                                        weight="600"
                                        className=""
                                    >
                                        Cuotas vencidas
                                    </Typography>
                                    <Typography
                                        scale="xsmall"
                                        weight="400"
                                        className=""
                                    >
                                        {priceFormatFinancial(
                                            financialForm?.credit?.payments
                                                .filter(
                                                    (ele: any) =>
                                                        ele.status === "expired"
                                                )
                                                .reduce(
                                                    (acc: any, ele: any) =>
                                                        acc + ele.total,
                                                    0
                                                ),
                                            0
                                        )}{" "}
                                        en mora
                                    </Typography>
                                </div>
                            </div>
                            {[
                                CreditStatus.writeOff,
                                CreditStatus.portfolioSale,
                            ].includes(financialForm?.credit.status) ? (
                                financialForm?.credit.writeOffAmount ? (
                                    <div className="br_xxs border_neutral_400 border_solid border_1 flex_basis_0 flex_grow_1 bg_neutral_0 p_y_xs p_x_md">
                                        <Typography
                                            scale="medium"
                                            weight="400"
                                            className=""
                                            textColor="neutral_800"
                                        >
                                            Valor de cuota
                                        </Typography>
                                        <Typography
                                            scale="large"
                                            weight="600"
                                            textColor="primary_300"
                                            className=""
                                        >
                                            {priceMXNTotal(
                                                financialForm.creditTotals
                                                    .amountPerPay,
                                                2
                                            )}
                                        </Typography>
                                    </div>
                                ) : (
                                    <div className="br_xxs border_neutral_400 border_solid border_1 flex_basis_0 flex_grow_1 bg_neutral_0 p_y_xs p_x_md display_flex flex_align_center">
                                        <Button
                                            scale="small"
                                            icon={<Wallet />}
                                            variant="outline"
                                            className="w_100_per"
                                            onClick={() => setOpenSettle(true)}
                                        >
                                            Liquidar crédito
                                        </Button>
                                    </div>
                                )
                            ) : null}
                        </div>
                        {financialForm?.credit.writeOffAmount ? (
                            <div className="display_flex flex_gap_md flex_align_stretch m_b_md">
                                <div className="br_xxs border_neutral_400 border_solid border_1 flex_basis_0 flex_grow_1 bg_neutral_0 p_y_xs p_x_md">
                                    <Typography
                                        scale="medium"
                                        weight="400"
                                        className=""
                                        textColor="neutral_800"
                                    >
                                        Monto castigado
                                    </Typography>
                                    <Typography
                                        scale="large"
                                        weight="600"
                                        textColor="neutral_800"
                                        className=""
                                    >
                                        {priceMXNTotal(
                                            financialForm.credit.writeOffAmount,
                                            0
                                        )}
                                    </Typography>
                                </div>
                                <div className="br_xxs border_neutral_400 border_solid border_1 flex_basis_0 flex_grow_1 bg_neutral_0 p_y_xs p_x_md">
                                    <Typography
                                        scale="medium"
                                        weight="400"
                                        className=""
                                        textColor="neutral_800"
                                    >
                                        Monto Liquidado
                                    </Typography>
                                    <Typography
                                        scale="large"
                                        weight="600"
                                        textColor="green_300"
                                        className=""
                                    >
                                        {priceMXNTotal(
                                            financialForm.credit
                                                .writeOffAmount *
                                                (1 -
                                                    financialForm.credit
                                                        .writeOffDiscount),
                                            0
                                        )}
                                    </Typography>
                                </div>
                                <div className="br_xxs border_neutral_400 border_solid border_1 flex_basis_0 flex_grow_1 bg_neutral_0 p_y_xs p_x_md">
                                    <Typography
                                        scale="medium"
                                        weight="400"
                                        className=""
                                        textColor="neutral_800"
                                    >
                                        Valor del descuento
                                    </Typography>
                                    <div className="flex_center flex_justify_between">
                                        <Typography
                                            scale="large"
                                            weight="600"
                                            textColor="neutral_800"
                                            className=""
                                        >
                                            {Math.round(
                                                financialForm.credit
                                                    .writeOffDiscount * 100
                                            )}
                                            %
                                        </Typography>
                                        <LightTooltip
                                            title={
                                                CreditStatusValues[
                                                    financialForm.credit
                                                        ?.status as keyof typeof CreditStatus
                                                ]?.tooltip ??
                                                financialForm.credit?.status
                                            }
                                        >
                                            <div>
                                                <Typography
                                                    scale="medium"
                                                    weight="600"
                                                    className="br_xs p_x_md p_y_xxxs text_center text_neutral_0 pos_relative
                                        bg_neutral_1000_nocontrast text_no_break"
                                                >
                                                    Write Off Liquidado
                                                </Typography>
                                            </div>
                                        </LightTooltip>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="display_flex flex_gap_md flex_align_stretch m_b_md">
                                <div className="br_xxs border_neutral_400 border_solid border_1 flex_basis_0 flex_grow_1 bg_neutral_0 p_y_xs p_x_md">
                                    <Typography
                                        scale="medium"
                                        weight="400"
                                        className=""
                                        textColor="neutral_800"
                                    >
                                        Deuda total
                                    </Typography>
                                    <Typography
                                        scale="large"
                                        weight="600"
                                        textColor="red_300"
                                        className=""
                                    >
                                        {priceMXNTotal(
                                            financialForm.creditTotals
                                                .totalDebt,
                                            2
                                        )}
                                    </Typography>
                                </div>
                                <div className="br_xxs border_neutral_400 border_solid border_1 flex_basis_0 flex_grow_1 bg_neutral_0 p_y_xs p_x_md">
                                    <Typography
                                        scale="medium"
                                        weight="400"
                                        className=""
                                        textColor="neutral_800"
                                    >
                                        Valor de cuota
                                    </Typography>
                                    <Typography
                                        scale="large"
                                        weight="600"
                                        textColor="primary_300"
                                        className=""
                                    >
                                        {priceMXNTotal(
                                            financialForm.creditTotals
                                                .amountPerPay,
                                            2
                                        )}
                                    </Typography>
                                </div>
                                <div className="br_xxs border_neutral_400 border_solid border_1 flex_basis_0 flex_grow_1 bg_neutral_0 p_y_xs p_x_md">
                                    <Typography
                                        scale="medium"
                                        weight="400"
                                        className=""
                                        textColor="neutral_800"
                                    >
                                        Deuda saldada
                                    </Typography>
                                    <Typography
                                        scale="large"
                                        weight="600"
                                        textColor="green_300"
                                        className=""
                                    >
                                        {priceMXNTotal(
                                            financialForm.creditTotals
                                                .totalPaid,
                                            2
                                        )}
                                    </Typography>
                                    <div className="display_flex flex_align_center flex_gap_md">
                                        <Typography
                                            scale="small"
                                            weight="600"
                                            textColor="green_300"
                                            className=""
                                        >
                                            {Math.round(
                                                (Number(
                                                    financialForm.creditTotals
                                                        .totalPaid
                                                ) *
                                                    100) /
                                                    Number(
                                                        financialForm
                                                            .creditTotals
                                                            .totalCredit
                                                    )
                                            )}
                                            %
                                        </Typography>
                                        <ProgressBar
                                            containerClassName="bg_neutral_400 h_sm"
                                            fillClassName="bg_green_300 h_sm"
                                            percentage={
                                                (Number(
                                                    financialForm.creditTotals
                                                        .totalPaid
                                                ) *
                                                    100) /
                                                Number(
                                                    financialForm.creditTotals
                                                        .totalCredit
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className="w-100-per">
                            <div className="m_y_xl">
                                <CreditPaymentsTable
                                    loading={loading}
                                    credit={financialForm?.credit}
                                />
                            </div>
                        </div>
                    </Grid>
                </Grid>
            )}
            {openSettle && (
                <ModalConfirmation
                    openConfirmation={openSettle}
                    handleClose={closeSettle}
                    icon={<User />}
                    isConfirmationLoading={false}
                    widthDesktop={30}
                    actions={[
                        {
                            label: "Cancelar",
                            color: "grey",
                            action: closeSettle,
                        },
                        {
                            label: "Liquidar",
                            color: "primary",
                            disabled: loadingSettle,
                            action: () => {
                                settleCredit();
                            },
                        },
                    ]}
                >
                    <>
                        <Typography
                            scale="large"
                            weight="600"
                            className="m_b_lg"
                        >
                            Liquidación de crédito
                        </Typography>
                        <Typography
                            scale="medium"
                            weight="400"
                            className="m_b_lg"
                        >
                            Selecciona el porcentaje de descuento para la
                            liquidación de este crédito
                        </Typography>

                        <Input
                            type="number"
                            onChange={(e: any) =>
                                setWriteOffAmount(parseInt(e.target.value, 10))
                            }
                            value={writeOffAmount}
                            title="Monto castigado"
                            icon={<Money />}
                            name="amount"
                            placeholder="Escribe el monto castigado"
                        />
                        <Input
                            type="number"
                            onChange={(e: any) =>
                                setWriteOffDiscount(
                                    parseInt(e.target.value, 10)
                                )
                            }
                            value={writeOffDiscount}
                            title="Porcentaje de descuento"
                            icon={<Discount />}
                            name="discount"
                            placeholder="Escribe el valor de descuento"
                        />
                        <div className="flex_center flex_justify_between">
                            <Typography
                                scale="large"
                                weight="400"
                                className="m_b_lg"
                            >
                                Total a pagar:
                            </Typography>
                            <Typography
                                scale="large"
                                weight="400"
                                className="m_b_lg"
                            >
                                {priceMXNTotal(
                                    writeOffAmount *
                                        (1 - writeOffDiscount / 100),
                                    0
                                )}
                            </Typography>
                        </div>
                    </>
                </ModalConfirmation>
            )}
            {openStatusChange && (
                <ModalConfirmation
                    openConfirmation={openStatusChange}
                    handleClose={() => {
                        setOpenStatusChange(false);
                        setSubstatusChange("");
                    }}
                    icon={<Refresh className="text_primary_300" />}
                    isConfirmationLoading={false}
                    actions={[
                        {
                            label: "Cancelar y volver",
                            color: "grey",
                            action: () => {
                                setOpenStatusChange(false);
                                setSubstatusChange("");
                            },
                        },
                        {
                            label: "Cambiar estado",
                            color: "primary",
                            loading: changeStatusLoading,
                            action: () => {
                                updateStatus(statusChange);
                            },
                        },
                    ]}
                    title={
                        <Typography scale="large" weight="400">
                            ¿Estás seguro de que{" "}
                            <Typography
                                scale="large"
                                weight="600"
                                component="span"
                            >
                                deseas modificar el estado del credito?{" "}
                            </Typography>
                        </Typography>
                    }
                    subtitle={
                        <>
                            <Typography
                                scale="medium"
                                weight="400"
                                textColor="neutral_700"
                            >
                                El estado del credito
                                <Typography
                                    scale="medium"
                                    weight="600"
                                    component="span"
                                >{` pasará de ${
                                    (CreditStatusValues as any)[
                                        financialForm.credit.status
                                    ]?.title ?? ""
                                } a ${
                                    (CreditStatusValues as any)[statusChange]
                                        ?.title
                                }.`}</Typography>
                            </Typography>
                            {statusChange === "devolution" ||
                            statusChange === "repossession" ? (
                                <Typography
                                    scale="medium"
                                    weight="600"
                                    textColor="red_300"
                                    className="m_t_md"
                                >
                                    *Ten en cuenta que pasar el ozoner a este
                                    estado eliminara el contrato y no se podra
                                    devolver al estado activo
                                </Typography>
                            ) : null}
                        </>
                    }
                />
            )}

            {openEnded && (
                <ModalConfirmation
                    openConfirmation={openEnded}
                    handleClose={() => {
                        setOpenEnded(false);
                        setEndedDate(null);
                    }}
                    icon={<Calendar className="text_primary_300" />}
                    isConfirmationLoading={false}
                    widthDesktop={30}
                    actions={[
                        {
                            label: "Cancelar",
                            color: "grey",
                            action: () => {
                                setOpenEnded(false);
                                setEndedDate(null);
                            },
                        },
                        {
                            label: "Guardar",
                            color: "primary",
                            action: () => {
                                setOpenEnded(false);
                                changeEndedDate();
                            },
                        },
                    ]}
                >
                    <>
                        <Typography
                            scale="large"
                            weight="600"
                            className="m_b_md"
                        >
                            Fecha de finalización de contrato{" "}
                        </Typography>
                        <Typography
                            scale="medium"
                            weight="400"
                            className="m_b_md"
                        >
                            Esta fecha hace referencia al momento en el que el
                            contrato finalizo o se da por finalizado
                        </Typography>

                        <Input
                            type="date"
                            onChange={(e: any) => setEndedDate(e.target.value)}
                            value={endedDate}
                            title=""
                            name="endedAt"
                            placeholder="Escribe el valor del depósito aquí"
                            className="date_select_ended"
                        />
                    </>
                </ModalConfirmation>
            )}
            {openTags && (
                <ModalConfirmation
                    openConfirmation={openTags}
                    handleClose={() => {
                        setOpenTags(false);
                        setEditTags([]);
                    }}
                    icon={<Tag className="text_primary_300" />}
                    isConfirmationLoading={isConfirmationLoading}
                    widthDesktop={30}
                    actions={[
                        {
                            label: "Cancelar",
                            color: "grey",
                            action: () => {
                                setOpenTags(false);
                                setEditTags([]);
                            },
                        },
                        {
                            label: "Guardar",
                            color: "primary",
                            disabled: isConfirmationLoading,
                            action: () => {
                                updateTags();
                            },
                        },
                    ]}
                >
                    <div className="h_30_vp">
                        <Typography
                            scale="large"
                            weight="600"
                            className="m_b_md"
                        >
                            Clasificación de crédito{" "}
                        </Typography>
                        <Typography
                            scale="medium"
                            weight="400"
                            className="m_b_md"
                        >
                            Asigne una clasificación para este crédito
                        </Typography>

                        <Select
                            fixedPlaceholder
                            variant="checkbox"
                            placeholder="Etiqueta"
                            options={Object.keys(CreditStatusFlagsValues).map(
                                (key) => ({
                                    value: key,
                                    label:
                                        CreditStatusFlagsValues[
                                            key as CreditStatusFlags
                                        ]?.title ?? key,
                                })
                            )}
                            value={editTags}
                            onChange={(e: any) => {
                                setEditTags(e.target.value);
                            }}
                        />
                    </div>
                </ModalConfirmation>
            )}
        </>
    );
}
export default CreditOzoner;
