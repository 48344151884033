/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */

import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import { ReactComponent as Add } from "design_system/src/static/icons/add-lg.svg";
import { ReactComponent as Moto } from "design_system/src/static/icons/motorcycle.svg";
import { ReactComponent as Chat } from "design_system/src/static/icons/group-chat.svg";
import { ReactComponent as CircleAdd } from "design_system/src/static/icons/add-circle.svg";
import { ReactComponent as Close } from "design_system/src/static/icons/close-circle.svg";
import { ReactComponent as Document } from "design_system/src/static/icons/document-tick.svg";
import { ReactComponent as Info } from "design_system/src/static/icons/info.svg";

import { ReactComponent as Mail } from "design_system/src/static/icons/mail.svg";
import { ReactComponent as GPS } from "design_system/src/static/icons/gps.svg";
import { ReactComponent as Calc } from "design_system/src/static/icons/calculator.svg";
import { ReactComponent as Time } from "design_system/src/static/icons/user-card-2.svg";

import {
    Button,
    ModalConfirmation as Modal,
    Typography,
    Input,
    CheckboxInput,
} from "design_system/src";
import moment from "moment";
import "moment/locale/es";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useFormik } from "formik";

import { editOzoner, setOzonerAgreements } from "helpers/fetchs/ozoners";
import { priceFormatFinancial, priceMXNTotal } from "helpers/prices/prices";
import { TDataozoner, statusAgreements } from "models/ozoner.interface";
import { fetchNotifications } from "helpers/fetchs/notifications";
import { fetchByPostalCode } from "helpers/fetchs/copomex";
import { RepossessionStatus } from "models/repossession.interface";
import { CreditStatus } from "models/credit.interface";
import { toasterNotification } from "helpers/globalNotifications/globalNotifications";
import { toasterOptions } from "enums/toaster.enum";
import {
    CreateCreditRepossession,
    CreateCreditRepossessionDevolution,
    EditRepossession,
} from "helpers/fetchs/repossession";
import CollectionNewCard from "../../../../components/hocs/collectionNewCard/collectionNewCard";
import useCollectionNotificationsApi from "../../../../hooks/useCollectionNotificationsApi";
import CollectionModal from "../modals/CollectionModal";
import LoaderIndicator from "../../../../components/ui/LoaderIndicator/LoaderIndicator";
import { getOzonios } from "../../../../helpers/fetchs/ozonios";
import { FetchEstimateRestructure, RestructureCredit, addFileCredit, editCredit } from "../../../../helpers/fetchs/credits";
import AgreementModal from "../modals/AgreementModal";
import { ozonerContext } from "../OzonerContext";
import { CollectionResponsible, CollectionSegmentation } from "./SolicitudOzoner/Segmentations";

const defaultsAgreement = {
    date: moment().format(),
    amount: "",
    comment: "",
    amountQuote: "",
};

const addressVS = yup.object({
    cp: yup
        .string()
        .required("Campo requerido")
        .matches(/^\d{5}$/, "Debe ser un número de 5 dígitos.")
        .test("cp", "El codigo postal no existe", function test(value) {
            const { state } = this.parent;
            if (value && value.length === 5 && !state) {
                return this.createError({
                    message: "El codigo postal no existe",
                });
            }
            return true;
        })
        .required("El código es requerido"),
    state: yup.string().required("Campo requerido"),
    delegation: yup.string().required("Campo requerido"),
    neighborhood: yup.string().required("Campo requerido"),
    street: yup.string().required("Campo requerido"),
    extNumber: yup.string().required("Campo requerido"),
    intNumber: yup.string(),
});

const formatName = (name: string) => name.replaceAll(" ", "_");

interface CollectionProps {}

let petition = 0;
const CollectionOzoner: React.FC<CollectionProps> = () => {
    const navigate = useNavigate();

    const {
        ozoner,
        financialForm,
        agreements,
        getAgreements,
        agreementsHist,
        setResult,
        setSelectedSegmentation,
        loadingSegmentation,
    } = useContext(ozonerContext);

    const [isCollectionCardAgreement, setIsCollectionCardAgreement] =
        useState(false);

    const [agreementCollectionNewId, setAgreementCollectionNewId] = useState<
        string | undefined
    >(undefined);

    // collectionn news States
    const [openCollectionNewModal, setOpenCollectionNewModal] = useState(false);
    const [collectionNewsArray, setCollectionNewsArray] = useState<any>([]);
    const [editingAgreement, setEditingAgreement] =
        useState<any>(defaultsAgreement);

    const [openAgreement, setOpenAgreement] = useState(false);
    const [openAgreements, setOpenAgreements] = useState(false);

    const [openRestructure, setOpenRestructure] = useState(false);
    const [restructureFetching, setRestructureFetching] = useState(false);
    const [restructureInfo, setRestructureInfo] = useState<any>();
    const [restructureLoading, setRestructureLoading] = useState(false);
    const [newPayment, setNewPayment] = useState();
    const [newDuration, setNewDuration] = useState();

    const { data, isLoading, postData, fetchData } =
        useCollectionNotificationsApi(ozoner._id);

    const [loading, setLoading] = useState(false);

    // select and contract States
    const [ozoniosOptions, setOzoniosOptions] = useState<any[]>([]);

    const [alarms, setAlarms] = useState([]);

    const [neighborhoodOptions, setNeighborhoodOptions] = useState([]);
    const [loadingCP, setloadingCP] = useState(false);

    const addressF = useFormik({
        initialValues: {
            cp: "",
            state: "",
            delegation: "",
            neighborhood: "",
            street: "",
            extNumber: "",
            intNumber: "",
        },
        validateOnMount: true,
        enableReinitialize: true,
        validationSchema: addressVS,
        onSubmit: () => {},
    });

    const [openRepossessionModal, setOpenRepossessionModal] = useState(false);
    const [repossessionFile, setRepossessionFile] = useState<File>();
    const [repossessionComment, setRepossessionComment] = useState("");
    const [openRepossessionCancelModal, setOpenRepossessionCancelModal] =
        useState(false);
    const [repossessionIsDevolution, setRepossessionIsDevolution] =
        useState(false);
    const [useOzonerAddress, setUseOzonerAddress] = useState(true);
    const [hasValidAddress, setHasValidAddress] = useState(false);

    useEffect(() => {
        getOzonios("")
            .then(({ data: ozoniosResponse }) => {
                const ozonios = ozoniosResponse.map((ozonio: any) => ({
                    value: ozonio._id,
                    label: ozonio.name,
                }));
                const ozoniosDesc = ozonios
                    .filter((ozonio: any) =>
                        [
                            "62e43be83fce23d2fb978518",
                            "64f62a459df5f1f39e704cea",
                        ].includes(ozonio.value)
                    )
                    .sort(({ label: a }: any, { label: b }: any) =>
                        a.localeCompare(b)
                    );
                setOzoniosOptions(ozoniosDesc);
            })
            .catch((e) => console.error(e.message));
    }, []);


    useEffect(() => {
        if (financialForm) {
            fetchNotifications(
                undefined,
                undefined,
                -1,
                undefined,
                financialForm.vehicle?.internalId
            ).then((info: any) => {
                setAlarms(info.data);
            });
        }
    }, [financialForm]);

    /**
     * Sort collection News cards in descending order
     * */
    useEffect(() => {
        if (data) {
            setCollectionNewsArray(
                data.sort((a, b) => {
                    // convert updatedAt strings to dates
                    const dateA = Date.parse(a.updatedAt);
                    const dateB = Date.parse(b.updatedAt);
                    // sort by descending order
                    return dateB - dateA;
                })
            );
        }
    }, [data]);
    useEffect(() => {
        addressVS
            .validate(ozoner.fullAddress)
            .then(() => setHasValidAddress(true))
            .catch((e) => setHasValidAddress(false));
    }, [repossessionIsDevolution, useOzonerAddress]);

    useEffect(() => {
        setRepossessionComment(ozoner?.comment ?? "");
        addressF.setValues(ozoner.fullAddress);
    }, [ozoner]);

    // this Effect Force to re-render the list when updated

    if (isLoading) {
        return <LoaderIndicator msg />;
    }

    const handleAddCollectionNew = () => {
        setOpenCollectionNewModal(true);
        setIsCollectionCardAgreement(true);
    };

    const changeAgreement = (idA: string, idP: string, status: string) => {
        if (ozoner?._id)
            setOzonerAgreements(idA, idP, status).then(() => {
                getAgreements(financialForm.credit._id);
            });
    };

    const createCollectionNew = (values: any) => {
        const newCollectionPayload = {
            ozoner: ozoner._id,
            ...values,
            status: values.status.label,
            agreement: agreementCollectionNewId ?? "",
        };

        postData(newCollectionPayload);
        setOpenCollectionNewModal(false);
    };

    const closeRepossessionModal = () => {
        setOpenRepossessionModal(false);
        setOpenRepossessionCancelModal(false);
        setRepossessionFile(undefined);
        setRepossessionComment(ozoner?.comment ?? "");
        setRepossessionIsDevolution(false);
        setUseOzonerAddress(true);
        addressF.resetForm();
    };
    const createRepossission = () => {
        if (repossessionFile) {
            const p = [];
            p.push(
                addFileCredit(
                    financialForm.credit._id,
                    repossessionFile,
                    "other",
                    repossessionIsDevolution?"Evidencia de devolucion":"Evidencia recuperacion"
                )
            );
            p.push(editOzoner({ comment: repossessionComment }, ozoner._id));
            if(repossessionIsDevolution){
                let addressData;
                if(useOzonerAddress){
                    if(hasValidAddress){
                        addressData={
                            cp:`${ozoner.fullAddress.cp}`,
                            street:`${ozoner.fullAddress.street}`,
                            extNumber:`${ozoner.fullAddress.extNumber}`,
                        };
                    }
                    else{
                        p.push(
                            editOzoner(
                                { fullAddress: {...addressF.values} },
                                ozoner._id
                            )
                        );
                        addressData={
                            cp:`${addressF.values.cp}`,
                            street:`${addressF.values.street}`,
                            extNumber:`${addressF.values.extNumber}`,
                        };
                    }
                }
                else{
                    addressData={
                        cp:`${addressF.values.cp}`,
                        street:`${addressF.values.street}`,
                        extNumber:`${addressF.values.extNumber}`,
                    };
                }
                p.push(
                    CreateCreditRepossessionDevolution(
                        financialForm.credit._id,
                        addressData
                    )
                );
            }
            else{
                p.push(CreateCreditRepossession(financialForm.credit._id));
            }

            Promise.all(p).then(() => {
                closeRepossessionModal();
                setResult();
            });
        }
    };
    const cancelRepossission = () => {
        if (financialForm.repossession) {
            const p = [];
            p.push(editOzoner({ comment: repossessionComment }, ozoner._id));
            p.push(
                EditRepossession(financialForm.repossession._id, {
                    status: RepossessionStatus.canceled,
                    comment: "cancelado por cobranza",
                })
            );

            Promise.all(p).then(() => {
                closeRepossessionModal();
                setResult();
            });
        }
    };

    const handleFile = (e: any) => {
        const file = e.target.files[0];
        if (file.size > 10000000) {
            Swal.fire({
                text: "La imagen no debe pesar mas de 10mb",
                icon: "warning",
                confirmButtonText: "Aceptar",
            });
        } else if (ozoner) {
            const blob = file.slice(0, file.size, file.type);
            const newFile = new File([blob], formatName(file.name), {
                type: file.type,
            });
            setRepossessionFile(newFile);
            // addFile(ozoner._id, newFile, fileType).then((newData: any) => {
            //   setResult(newData);
            // });
        }
    };

    const clearPostalCode = () => {
        addressF.setFieldValue("state", "");
        addressF.setFieldValue("delegation", "");
        addressF.setFieldValue("neighborhood", "");
        setNeighborhoodOptions([]);
        addressF.setFieldTouched("cp", true, true);
    };

    const updatePostalCode = async (value: string) => {
        if (value.length === 5) {
            setloadingCP(true);
            fetchByPostalCode(value)
                .then((response: any) => {
                    setloadingCP(false);
                    const info = response?.data;
                    if (!info.error) {
                        addressF.setFieldValue("state", info.response.estado);
                        addressF.setFieldValue(
                            "delegation",
                            info.response.municipio
                        );
                        addressF.setFieldValue("neighborhood", "");
                        setNeighborhoodOptions(info.response.asentamiento);
                        addressF.setFieldTouched("cp", true, true);
                    }
                })
                .catch((e) => {
                    setloadingCP(false);
                    clearPostalCode();
                });
        } else {
            clearPostalCode();
        }
    };
    const closeRestructure = () => {
                        setOpenRestructure(false);
                        setRestructureInfo(undefined);
                        setNewDuration(undefined);
                        setNewPayment(undefined);
                        setRestructureFetching(false);
                        setRestructureLoading(false);
                    };

    const fetchReestructure = (value:string,type:boolean)=>{
    petition = Date.now();
    const request = petition;
        setRestructureInfo(undefined);
        if(value){
            const values = {
                [type?"quota":"weeks"]:parseInt(value,10)
            };
            setRestructureFetching(true);
            FetchEstimateRestructure(financialForm.credit._id, values).then(result=>{
            if(petition===request){

                setRestructureFetching(false);
                setRestructureInfo(result);
                setNewPayment(result.newPaymentWeek);
                setNewDuration(result.newDuration);
                }
            }).catch((e) => {
                setRestructureFetching(false);
                toasterNotification({
                    msg: e ?? "Ocurrio un error",
                    toasterType: toasterOptions.error,
                    style: "light",
                });
            });
    }
    };
    const applyRestructure = ()=>{
        if(newPayment && newDuration){
            const values = {
                paymentCapacity: parseInt(`${newPayment}`, 10),
            };
            setRestructureLoading(true);
            RestructureCredit(financialForm.credit._id, values).then(()=>{
                setRestructureLoading(false);
                closeRestructure();
                setResult();
            }).catch((e) => {
                setRestructureLoading(false);
                toasterNotification({
                    msg: e?.message ?? "Ocurrio un error",
                    toasterType: toasterOptions.error,
                    style: "light",
                });
            });
    }
    };



    // window.open(`https://app7.porcobrar.com/portal/${porcobrarId}`, "_blank");

    return (
        <>
            <Grid container direction="row" spacing={3} className="">
                <Grid item xs={3}>
                    <div className="bg_neutral_0 border_neutral_400 border_solid border_1 br_sm">
                        <div
                            id="responsable-cobranza"
                            className="p_md border_b_solid border_1 border_neutral_400"
                        >
                            <CollectionResponsible/>
                        </div>
                        <div
                            id="segmentacion-cobranza"
                            className="p_md border_b_solid border_1 border_neutral_400"
                        >
                            <CollectionSegmentation/>
                        </div>
                        <div
                            id="acuerdos-cobranza"
                            className="p_md border_b_solid border_1 border_neutral_400"
                        >
                            <Typography
                                scale="medium"
                                weight="400"
                                className=" m_b_xs"
                            >
                                Ayuda para Ozoners
                            </Typography>
                            {!agreements ? (
                                <Button
                                    scale="small"
                                    variant="outline"
                                    className="w_100_per m_b_sm"
                                    icon={<Chat />}
                                    onClick={() => setOpenAgreement(true)}
                                >
                                    Crear acuerdo de pago
                                </Button>
                            ) : null}
                            {agreementsHist?.length ? (
                                <Button
                                    scale="small"
                                    variant="outline"
                                    className="w_100_per m_b_md"
                                    onClick={() => setOpenAgreements(true)}
                                >
                                    Ver historial de Acuerdos de pago
                                </Button>
                            ) : null}
                        </div>
                        {agreements ? (
                            <div className="p_md w_100_per border_b_solid border_1 border_neutral_400">
                                <Typography
                                    scale="medium"
                                    weight="400"
                                    className="m_b_xs"
                                >
                                    Acuerdo de pago
                                </Typography>
                                {agreements.agreementParts.map((ele: any) => (
                                    <div className="br_sm shadow_hard overflow_hidden bg_neutral_200 m_b_md pos_relative">
                                        <div className="w_per_100 bg_neutral_0 p_md p_b_sm display_flex flex_justify_between flex_align_center">
                                            <Typography
                                                scale="small"
                                                weight="600"
                                                textColor="neutral_1000"
                                                className=""
                                            >
                                                {moment
                                                    .utc(ele.date)
                                                    .locale("es")
                                                    .format("dddd, DD MMMM")}
                                            </Typography>
                                            <div className="display_flex flex_gap_md flex_align_center">
                                                <Typography
                                                    scale="small"
                                                    weight="600"
                                                    textColor="green_300"
                                                    className=""
                                                >
                                                    {priceMXNTotal(
                                                        ele.amount,
                                                        0
                                                    )}
                                                </Typography>
                                                {/* <Pencil className="dim_md text_neutral_600 cursor_pointer"
                                onClick={() => { editAgreement(agreements); }}
                              /> */}
                                            </div>
                                        </div>
                                        <div className="p_y_sm p_x_sm">
                                            <div className="display_flex flex_gap_md flex_align_center">
                                                <Typography
                                                    scale="xsmall"
                                                    weight="600"
                                                    textColor="neutral_1000"
                                                    className=""
                                                >
                                                    Comentarios
                                                </Typography>
                                                <Typography
                                                    scale="xxsmall"
                                                    weight="400"
                                                    textColor="secondary_600"
                                                    className="cursor_pointer"
                                                    role="button"
                                                    tabIndex={0}
                                                >
                                                    Leer todo
                                                </Typography>
                                            </div>
                                            <Typography
                                                scale="xxsmall"
                                                weight="400"
                                                textColor="neutral_600"
                                                className="h_lg overflow_hidden"
                                            >
                                                {ele.comment}
                                            </Typography>
                                            {ele.status ===
                                            statusAgreements.onCourse ? (
                                                <div className="display_flex flex_gap_xs m_t_sm">
                                                    <Button
                                                        scale="small"
                                                        variant="outline"
                                                        className="flex_basis_0 flex_grow_1"
                                                        onClick={() =>
                                                            changeAgreement(
                                                                agreements._id,
                                                                ele._id,
                                                                statusAgreements.failed
                                                            )
                                                        }
                                                    >
                                                        No pagó
                                                    </Button>
                                                    <Button
                                                        scale="small"
                                                        className="flex_basis_0 flex_grow_1"
                                                        onClick={() =>
                                                            changeAgreement(
                                                                agreements._id,
                                                                ele._id,
                                                                statusAgreements.accomplished
                                                            )
                                                        }
                                                    >
                                                        Pago recibido
                                                    </Button>
                                                </div>
                                            ) : (
                                                <div
                                                    className={`agreement_flag ${ele.status}`}
                                                >
                                                    <Typography
                                                        scale="xxsmall"
                                                        weight="600"
                                                        textColor="neutral_0"
                                                    >
                                                        {ele.status ===
                                                        statusAgreements.accomplished
                                                            ? "Pagado"
                                                            : "Fallido"}
                                                    </Typography>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : null}
                        {![
                            CreditStatus.canceled,
                            CreditStatus.complete,
                            CreditStatus.devolution,
                            CreditStatus.repossession,
                        ].includes(financialForm.credit.status) && (
                            <div
                                id="repossession-cobranza"
                                className="p_md border_b_solid border_1 border_neutral_400"
                            >
                                <Typography
                                    scale="medium"
                                    weight="400"
                                    className=" m_b_xs"
                                >
                                    Generar una recuperacion
                                </Typography>
                                {financialForm.repossession &&
                                financialForm.repossession.status !==
                                    RepossessionStatus.canceled &&
                                financialForm.repossession.status !==
                                    RepossessionStatus.completed ? (
                                    <>
                                        <div className="flex_center flex_gap_md bg_red_200 text_red_300 br_xs p_y_xs w_100_per m_b_md">
                                            <Info className="dim_lg" />
                                            <Typography
                                                scale="small"
                                                weight="600"
                                                className=""
                                            >
                                                Vehículo en recuperación
                                            </Typography>
                                        </div>
                                        <Button
                                            scale="small"
                                            variant="outline"
                                            className="w_100_per"
                                            icon={<Close />}
                                            onClick={() =>
                                                setOpenRepossessionCancelModal(
                                                    true
                                                )
                                            }
                                        >
                                            Cancelar recuperación
                                        </Button>
                                    </>
                                ) : (
                                    <Button
                                        scale="small"
                                        variant="outline"
                                        className="w_100_per"
                                        icon={<Moto />}
                                        onClick={() =>
                                            setOpenRepossessionModal(true)
                                        }
                                    >
                                        Recuperar el vehiculo
                                    </Button>
                                )}
                            </div>
                        )}
                        {![
                            CreditStatus.canceled,
                            CreditStatus.complete,
                            CreditStatus.devolution,
                            CreditStatus.repossession,
                        ].includes(financialForm.credit.status) && (
                            <div
                                id="restructure-cobranza"
                                className="p_md border_b_solid border_1 border_neutral_400"
                            >
                                <Typography
                                    scale="medium"
                                    weight="400"
                                    className=" m_b_xs"
                                >
                                    Re-estructurar crédito
                                </Typography>

                                <Button
                                    scale="small"
                                    variant="outline"
                                    className="w_100_per"
                                    onClick={() => setOpenRestructure(true)}
                                >
                                    Re-estructurar
                                </Button>
                            </div>
                        )}
                    </div>
                </Grid>
                <Grid item xs={5}>
                    <div className="br_xxs bg_neutral_100  border_1 border_solid border_neutral_400 pos_relative">
                        <div className="display_flex flex_align_center flex_justify_between p_y_md p_x_xl bg_neutral_0">
                            <div>
                                <Typography
                                    scale="large"
                                    weight="600"
                                    className=" "
                                >
                                    Novedades
                                </Typography>
                                <Typography
                                    scale="medium"
                                    weight="400"
                                    className=" "
                                >
                                    Define aquí cualquier novedad referente al
                                    ozoner
                                </Typography>
                            </div>
                            <Button
                                onClick={handleAddCollectionNew}
                                className="m_t_sm"
                                scale="small"
                                icon={<Add />}
                            >
                                Añadir novedad
                            </Button>
                        </div>
                        <Grid
                            container
                            spacing={2}
                            columns={16}
                            className="p_md h_40_vp bg_neutral_300 m_l_none m_t_none w_100_per overflow_y_auto"
                        >
                            {collectionNewsArray.map((item: any) => {
                                // si el item tiene agreement  buscarlo en todas las novedades
                                let agreementFound;
                                if (
                                    item.agreement &&
                                    agreements?.agreementParts
                                ) {
                                    agreementFound =
                                        agreements.agreementParts.find(
                                            (agreement: any) => {
                                                return (
                                                    agreement._id ===
                                                    item.agreement
                                                );
                                            }
                                        );
                                }
                                return (
                                    <CollectionNewCard
                                        key={item._id}
                                        ozonerId={ozoner._id}
                                        collectionNewId={item._id}
                                        title={item.status}
                                        description={item.content}
                                        type={item.result}
                                        agreementCollectionNewId={
                                            agreementCollectionNewId
                                        }
                                        updatedAt={item.updatedAt}
                                        setCollectionNewsArray={
                                            setCollectionNewsArray
                                        }
                                        setUpdateComponent={() => {
                                            setResult();
                                            setAgreementCollectionNewId(
                                                undefined
                                            );
                                        }}
                                        setOpenAgreement={setOpenAgreement}
                                        setIsCollectionCardAgreement={
                                            setIsCollectionCardAgreement
                                        }
                                        agreementDetail={agreementFound}
                                        collectionData={item}
                                    />
                                );
                            })}
                        </Grid>
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <div className="br_xxs bg_neutral_100  border_1 border_solid border_neutral_400 pos_relative">
                        <div className="display_flex flex_align_center flex_justify_between p_y_md p_x_xl bg_neutral_0">
                            <Typography
                                scale="large"
                                weight="600"
                                className=" "
                            >
                                Alertas del Crédito
                            </Typography>
                        </div>
                        <div className="p_lg p_r_sm display_flex flex_gap_sm flex_col bg_neutral_400">
                            <div className="alarms_section p_r_sm">
                                {alarms.map((alarm: any) => (
                                    <div
                                        className="p_x_lg p_y_sm br_md m_b_xs bg_neutral_0 display_flex flex_gap_lg "
                                        key={alarm._id}
                                    >
                                        {!alarm.read && (
                                            <div className="dim_sm br_circle bg_red_300 flex_shrink_0" />
                                        )}
                                        <div className="flex_grow_1">
                                            <div className="display_flex flex_align_center m_b_md flex_justify_between ">
                                                <Typography
                                                    scale="small"
                                                    weight="600"
                                                    textColor="neutral_900"
                                                >
                                                    {alarm.title}
                                                </Typography>
                                                <Typography
                                                    scale="xsmall"
                                                    weight="600"
                                                    textColor="neutral_600"
                                                >
                                                    {moment
                                                        .utc(alarm.createdAt)
                                                        .format("DD/MM/YYYY")}
                                                </Typography>
                                            </div>
                                            <Typography
                                                scale="xsmall"
                                                weight="400"
                                                textColor="neutral_600"
                                            >
                                                {alarm.description}
                                            </Typography>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </Grid>
            </Grid>
            <AgreementModal
                openAgreement={openAgreement}
                setOpenAgreement={setOpenAgreement}
                editingAgreement={editingAgreement}
                openAgreements={openAgreements}
                setOpenAgreements={setOpenAgreements}
                setEditingAgreement={setEditingAgreement}
            />

            {openRepossessionModal && (
                <Modal
                    openConfirmation={openRepossessionModal}
                    handleClose={closeRepossessionModal}
                    // onConfirmationCallBack={addAgreement}
                    // eslint-disable-next-line react/jsx-no-useless-fragment
                    icon={<Moto className="text_primary_300" />}
                    widthDesktop={40}
                    isConfirmationLoading={false}
                    actions={[
                        {
                            label: "Cancelar",
                            color: "grey",
                            action: closeRepossessionModal,
                        },
                        {
                            label: "Aceptar",
                            color: "primary",
                            action: () => createRepossission(),
                            disabled:
                                !repossessionFile ||
                                (repossessionIsDevolution &&
                                    ((useOzonerAddress &&
                                        !hasValidAddress &&
                                        !addressF.isValid) ||
                                        (!useOzonerAddress &&
                                            (!addressF.isValid ||
                                                !addressF.dirty)))),
                        },
                    ]}
                >
                    <>
                        <Typography
                            scale="large"
                            weight="600"
                            className="m_b_xs"
                        >
                            Deseas recuperar el vehículo
                        </Typography>
                        <Typography
                            scale="medium"
                            weight="400"
                            className="m_b_md"
                            textColor="neutral_700"
                        >
                            Al activar esta opción el equipo de recuperaciones
                            podrá ver con prioridad este vehículo e iniciara un
                            proceso de recuperación adjunta un pantallazo del
                            contacto del Ozoner y llena las observaciones para
                            la recuperación.
                        </Typography>

                        <div className="display_flex flex_align_center flex_justify_between flex_gap_xxl">
                            <Typography
                                scale="medium"
                                weight="600"
                                textColor="neutral_900"
                            >
                                ¿Es una devolución voluntaria del Ozoner?
                            </Typography>
                            <div className="flex_center bg_neutral_300 br_xs">
                                <Input
                                    title=""
                                    name="isDevolution"
                                    type="slider"
                                    labels
                                    value={repossessionIsDevolution}
                                    onChange={(e: any) =>
                                        setRepossessionIsDevolution(
                                            e.target.value
                                        )
                                    }
                                />
                            </div>
                        </div>
                        {repossessionIsDevolution && (
                            <>
                                <div className="flex_center">
                                    <CheckboxInput
                                        className="m_b_lg flex_grow_0"
                                        checked={useOzonerAddress}
                                        value=""
                                        label="¿La devolución es en el domicilio del Ozoner?"
                                        onChange={() => {
                                            if (!useOzonerAddress) {
                                                addressF.setValues(
                                                    ozoner.fullAddress ?? {}
                                                );
                                            } else {
                                                addressF.resetForm();
                                            }
                                            setUseOzonerAddress(
                                                !useOzonerAddress
                                            );
                                        }}
                                    />
                                </div>
                                {!hasValidAddress ? (
                                    <div
                                        id="info-soli-ozoner"
                                        className="p_xs flex_center_col bg_red_200 flex_gap_xs br_sm shadow_soft border_1 border_solid border_neutral_400 m_b_lg"
                                    >
                                        <div className="flex_center flex_gap_xs">
                                            <Info className="dim_lg text_red_300" />
                                            <Typography
                                                scale="small"
                                                weight="600"
                                                textColor="red_300"
                                                className="text_center"
                                            >
                                                La dirección del ozoner esta
                                                incompleta, llenar correctamente
                                                antes de continuar
                                            </Typography>
                                        </div>
                                    </div>
                                ) : null}
                                {!useOzonerAddress || !hasValidAddress ? (
                                    <Grid
                                        container
                                        direction="row"
                                        rowSpacing={0}
                                        columnSpacing={6}
                                        justifyContent="center"
                                        className=""
                                    >
                                        <Grid item xs={12}>
                                            <Input
                                                onBlur={(e) => {
                                                    addressF.handleBlur(e);
                                                }}
                                                error={
                                                    !!addressF.errors.cp &&
                                                    addressF.getFieldMeta("cp")
                                                        .touched
                                                }
                                                subtitle={
                                                    addressF.getFieldMeta("cp")
                                                        .touched
                                                        ? (addressF.errors
                                                              .cp as string)
                                                        : undefined
                                                }
                                                title="Código postal*"
                                                name="cp"
                                                type="text"
                                                className="m_b_xs w_100_per"
                                                icon={<Mail />}
                                                placeholder="Escribe el código postal"
                                                value={addressF.values.cp}
                                                onChange={(e: any) => {
                                                    addressF.handleChange(e);
                                                    updatePostalCode(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            {loadingCP ? (
                                                <Typography
                                                    scale="large"
                                                    weight="600"
                                                    textColor="neutral_700"
                                                    className="m_y_sm text_center"
                                                >
                                                    Cargando...
                                                </Typography>
                                            ) : null}
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Input
                                                onBlur={addressF.handleBlur}
                                                error={
                                                    !!addressF.errors.state &&
                                                    addressF.getFieldMeta(
                                                        "state"
                                                    ).touched
                                                }
                                                subtitle={
                                                    addressF.getFieldMeta(
                                                        "state"
                                                    ).touched
                                                        ? (addressF.errors
                                                              .state as string)
                                                        : undefined
                                                }
                                                title="Estado*"
                                                name="state"
                                                icon={<GPS />}
                                                disabled
                                                placeholder="Selecciona el estado"
                                                type="text"
                                                className="m_b_xs w_100_per"
                                                value={addressF.values.state}
                                                onChange={(e: any) => {
                                                    addressF.handleChange(e);
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Input
                                                onBlur={addressF.handleBlur}
                                                error={
                                                    !!addressF.errors
                                                        .delegation &&
                                                    addressF.getFieldMeta(
                                                        "delegation"
                                                    ).touched
                                                }
                                                subtitle={
                                                    addressF.getFieldMeta(
                                                        "delegation"
                                                    ).touched
                                                        ? (addressF.errors
                                                              .delegation as string)
                                                        : undefined
                                                }
                                                title="Delegación*"
                                                name="delegation"
                                                icon={<GPS />}
                                                placeholder="Selecciona la delegac"
                                                type="text"
                                                disabled
                                                className="m_b_xs w_100_per"
                                                value={
                                                    addressF.values.delegation
                                                }
                                                onChange={(e: any) => {
                                                    addressF.handleChange(e);
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Input
                                                onBlur={addressF.handleBlur}
                                                error={
                                                    !!addressF.errors
                                                        .neighborhood &&
                                                    addressF.getFieldMeta(
                                                        "neighborhood"
                                                    ).touched
                                                }
                                                subtitle={
                                                    addressF.getFieldMeta(
                                                        "neighborhood"
                                                    ).touched
                                                        ? (addressF.errors
                                                              .neighborhood as string)
                                                        : undefined
                                                }
                                                title="Colonia*"
                                                name="neighborhood"
                                                icon={<GPS />}
                                                placeholder="Selecciona la colonia"
                                                type="select"
                                                options={neighborhoodOptions}
                                                className="m_b_xs w_100_per"
                                                value={
                                                    addressF.values.neighborhood
                                                }
                                                onChange={(e: any) => {
                                                    addressF.handleChange(e);
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Input
                                                onBlur={addressF.handleBlur}
                                                error={
                                                    !!addressF.errors.street &&
                                                    addressF.getFieldMeta(
                                                        "street"
                                                    ).touched
                                                }
                                                subtitle={
                                                    addressF.getFieldMeta(
                                                        "street"
                                                    ).touched
                                                        ? (addressF.errors
                                                              .street as string)
                                                        : undefined
                                                }
                                                title="Calle*"
                                                name="street"
                                                icon={<GPS />}
                                                placeholder="Escribe nombre de la calle "
                                                type="text"
                                                className="m_b_xs w_100_per"
                                                value={addressF.values.street}
                                                onChange={(e: any) => {
                                                    addressF.handleChange(e);
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Input
                                                onBlur={addressF.handleBlur}
                                                error={
                                                    !!addressF.errors
                                                        .extNumber &&
                                                    addressF.getFieldMeta(
                                                        "extNumber"
                                                    ).touched
                                                }
                                                subtitle={
                                                    addressF.getFieldMeta(
                                                        "extNumber"
                                                    ).touched
                                                        ? (addressF.errors
                                                              .extNumber as string)
                                                        : undefined
                                                }
                                                title="Numero Exterior* "
                                                name="extNumber"
                                                icon={<GPS />}
                                                placeholder="Escribe el numero exterior"
                                                type="text"
                                                className="m_b_xs w_100_per"
                                                value={
                                                    addressF.values.extNumber
                                                }
                                                onChange={(e: any) => {
                                                    addressF.handleChange(e);
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Input
                                                onBlur={addressF.handleBlur}
                                                error={
                                                    !!addressF.errors
                                                        .intNumber &&
                                                    addressF.getFieldMeta(
                                                        "intNumber"
                                                    ).touched
                                                }
                                                subtitle={
                                                    addressF.getFieldMeta(
                                                        "intNumber"
                                                    ).touched
                                                        ? (addressF.errors
                                                              .intNumber as string)
                                                        : undefined
                                                }
                                                title="Numero Interior"
                                                name="intNumber"
                                                icon={<GPS />}
                                                placeholder="Escribe el numero interior"
                                                type="text"
                                                className="m_b_xs w_100_per"
                                                value={
                                                    addressF.values.intNumber
                                                }
                                                onChange={(e: any) => {
                                                    addressF.handleChange(e);
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                ) : null}
                            </>
                        )}
                        <Typography
                            scale="medium"
                            weight="600"
                            className="m_b_xs"
                        >
                            Evidencia de recuperación
                        </Typography>
                        {repossessionFile ? (
                            <div className="w_100_per bg_neutral_0 dso_card border_green_300 border_solid p_sm flex_center cursor_pointer flex_justify_between pos_relative document_card_loaded p_r_xxxl">
                                <div className="border_solid border_1 border_neutral_700 br_md h_95_per w_40_per overflow_hidden">
                                    <img
                                        src={URL.createObjectURL(
                                            repossessionFile
                                        )}
                                        alt="Documento de evidencia"
                                        className="image"
                                    />
                                </div>
                                <div className="flex_center_col flex_justify_between flex_gap_md">
                                    <Document className="dim_xl text_green_300" />

                                    <Typography
                                        scale="small"
                                        weight="600"
                                        className="text_center"
                                        textColor="green_300"
                                    >
                                        Evidencia subida
                                    </Typography>
                                </div>
                                <input
                                    type="file"
                                    onChange={(e: any) => handleFile(e)}
                                    className="input-file"
                                />
                            </div>
                        ) : (
                            <div className="w_100_per bg_neutral_0 dso_card border_primary_200 border_dashed p_sm flex_center_col cursor_pointer flex_justify_between pos_relative document_card">
                                <CircleAdd className="dim_xl text_primary_300" />

                                <Typography
                                    scale="small"
                                    weight="600"
                                    className="text_center"
                                    textColor="primary_300"
                                >
                                    Añadir Evidencia
                                </Typography>
                                <input
                                    type="file"
                                    onChange={(e: any) => handleFile(e)}
                                    className="input-file"
                                />
                            </div>
                        )}

                        <Typography
                            scale="large"
                            weight="600"
                            textColor="neutral_900"
                            className="m_y_md"
                        >
                            Observaciones del ozoner :
                        </Typography>
                        <textarea
                            className="observaciones w_100_per p_md border_1 border_solid border_neutral_400"
                            placeholder="Agregue todas las observaciones del ozoner que se detecten"
                            value={repossessionComment}
                            onChange={(e: any) =>
                                setRepossessionComment(e.target.value)
                            }
                        />
                    </>
                </Modal>
            )}
            {openRepossessionCancelModal && (
                <Modal
                    openConfirmation={openRepossessionCancelModal}
                    handleClose={closeRepossessionModal}
                    // onConfirmationCallBack={addAgreement}
                    // eslint-disable-next-line react/jsx-no-useless-fragment
                    icon={<Moto className="text_primary_300" />}
                    widthDesktop={40}
                    isConfirmationLoading={false}
                    actions={[
                        {
                            label: "Cancelar",
                            color: "grey",
                            action: closeRepossessionModal,
                        },
                        {
                            label: "Aceptar",
                            color: "primary",
                            action: () => cancelRepossission(),
                        },
                    ]}
                >
                    <>
                        <Typography
                            scale="large"
                            weight="600"
                            className="m_b_xs"
                        >
                            Deseas cancelar la recuperación del vehículo
                        </Typography>
                        <Typography
                            scale="medium"
                            weight="400"
                            className="m_b_md"
                            textColor="neutral_700"
                        >
                            El equipo de recuperaciones no podrá ver con este
                            vehículo ni recuperarlo
                        </Typography>

                        <Typography
                            scale="large"
                            weight="600"
                            textColor="neutral_900"
                            className="m_y_md"
                        >
                            Observaciones del ozoner :
                        </Typography>
                        <textarea
                            className="observaciones w_100_per p_md border_1 border_solid border_neutral_400"
                            placeholder="Agregue todas las observaciones del ozoner que se detecten"
                            value={repossessionComment}
                            onChange={(e: any) =>
                                setRepossessionComment(e.target.value)
                            }
                        />
                    </>
                </Modal>
            )}
            {openCollectionNewModal && (
                <CollectionModal
                    setOpenAgreement={setOpenAgreement}
                    openCollection={openCollectionNewModal}
                    setOpenCollection={setOpenCollectionNewModal}
                    isLoading={isLoading}
                    save={createCollectionNew}
                />
            )}

            {openRestructure && (
                <Modal
                    openConfirmation={openRestructure}
                    handleClose={closeRestructure}
                    icon={<Calc className="text_primary_300" />}
                    isConfirmationLoading={false}
                    widthDesktop={40}
                    actions={[
                        {
                            label: "Cancelar",
                            color: "grey",
                            action: closeRestructure,
                        },
                        {
                            label: "Reestructurar Crédito",
                            color: "primary",
                            disabled: restructureLoading || restructureFetching,
                            action: applyRestructure,
                        },
                    ]}
                >
                    <div className="">
                        <Typography
                            scale="large"
                            weight="600"
                            className="m_b_md"
                        >
                            Re-estructurar crédito
                        </Typography>
                        <Typography
                            scale="small"
                            weight="400"
                            className="m_b_md"
                        >
                            Simula la re-estructuración del crédito según la
                            capacidad del Ozoner, pon un valor de pago o un
                            numero de cuotas para empezar la simulación
                        </Typography>
                        <div className="display_flex flex_gap_md flex_align_center">
                            <Input
                                type="number"
                                name="newPayment"
                                title="Capacidad de Pago"
                                placeholder="0 OMX"
                                value={newPayment}
                                icon={<Chat />}
                                onChange={(e: any) => {
                                    setNewPayment(e.target.value);
                                    fetchReestructure(e.target.value, true);
                                }}
                                className="flex_basis_0 flex_grow_1"
                            />
                            <Input
                                className="flex_basis_0 flex_grow_1"
                                type="number"
                                icon={<Time />}
                                name="newDurarion"
                                title="Numero de cuotas"
                                placeholder="0 cuotas"
                                value={newDuration}
                                onChange={(e: any) => {
                                    setNewDuration(e.target.value);
                                    fetchReestructure(e.target.value, false);
                                }}
                            />
                        </div>
                        {restructureInfo ? (
                            <>
                                <div className="bg_neutral_0 p_xs display_flex flex_align_center flex_justify_between shadow_soft m_b_xs">
                                    <Typography
                                        scale="medium"
                                        weight="400"
                                        className=""
                                    >
                                        Deuda Total Actual
                                    </Typography>
                                    <Typography
                                        scale="large"
                                        weight="600"
                                        className=""
                                        textColor="primary_300"
                                    >
                                        {priceFormatFinancial(
                                            restructureInfo.debt
                                        )}
                                    </Typography>
                                </div>
                                <div className="display_flex flex_align_center flex_justify_between flex_gap_md">
                                    <div className="bg_neutral_0 shadow_soft flex_basis_0 flex_grow_1 p_xs">
                                        <Typography
                                            scale="medium"
                                            weight="400"
                                            className="m_b_xs"
                                        >
                                            Deuda Total Nueva
                                        </Typography>
                                        <Typography
                                            scale="large"
                                            weight="600"
                                            className=""
                                            textColor="red_300"
                                        >
                                            {priceFormatFinancial(
                                                restructureInfo.newLoanAmount
                                            )}
                                        </Typography>
                                    </div>
                                    <div className="bg_neutral_0 shadow_soft flex_basis_0 flex_grow_1 p_xs">
                                        <Typography
                                            scale="medium"
                                            weight="400"
                                            className="m_b_xs"
                                        >
                                            Pago Excedente
                                        </Typography>
                                        <Typography
                                            scale="large"
                                            weight="600"
                                            className=""
                                            textColor="green_300"
                                        >
                                            {priceFormatFinancial(
                                                restructureInfo.excess
                                            )}
                                        </Typography>
                                    </div>
                                </div>
                                {restructureLoading ? (
                                    <Typography
                                        scale="large"
                                        weight="600"
                                        className="p_y_md text_center w_100_per"
                                    >
                                        Realizando re-estructuracion, por favor espere...
                                    </Typography>
                                ) : null}
                            </>
                        ) : restructureFetching ? (
                            <Typography
                                scale="large"
                                weight="600"
                                className="p_y_md text_center w_100_per"
                            >
                                Cargando simulacion...
                            </Typography>
                        ) : (
                            <Typography
                                scale="large"
                                weight="600"
                                className="p_y_md text_center w_100_per"
                            >
                                No hay datos para simular
                            </Typography>
                        )}
                    </div>
                </Modal>
            )}
        </>
    );
};

export default CollectionOzoner;
